import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { checkAccessToStatusImport } from 'redux/importIndicatorStatus/selectors';
import { checkAccessToImport } from 'redux/importIndicatorData/selectors';
import { actions as importActions } from 'redux/importIndicatorData';
import { actions as importStatusActions } from 'redux/importIndicatorStatus';
import { actions as updateIndicatorNotesActions } from 'redux/updateIndicatorNotes';

import { formatTitleForCard } from 'helpers/formatTitleForCard';
import getPraparedValueForShow from 'helpers/valueToShow';
import { STATUSES } from 'helpers/statuses';
import checkHightlightsLowlights from 'helpers/checkHighlightsLowLights';
import Score from 'components/Score';
import ImportButtonCorner from '../ImportButtonCorner';

import * as S from './styled';

class Indicator extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    indicatorsGroupId: PropTypes.number.isRequired,
    reportingPeriodType: PropTypes.string.isRequired,
    reportingPeriod: PropTypes.string.isRequired,
    milestoneLink: PropTypes.string.isRequired,
    mapLink: PropTypes.string.isRequired,
    chartLink: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    lastTarget: PropTypes.object,
    value: PropTypes.number,
    valueType: PropTypes.string,
    target: PropTypes.number,
    status: PropTypes.string,
    manualStatus: PropTypes.string,
    note: PropTypes.string,
    highlightsTitle: PropTypes.string,
    lowlightsTitle: PropTypes.string,
    highlights: PropTypes.string,
    lowlights: PropTypes.string,
    hasAccessToStatusImport: PropTypes.bool.isRequired,
    hasAccessToImport: PropTypes.bool.isRequired,
    indicatorsMilestones: PropTypes.array,
    actions: PropTypes.shape({
      openImportStatusModalState: PropTypes.func.isRequired,
      openIndicatorNotesModalState: PropTypes.func.isRequired,
      closeImportModalState: PropTypes.func.isRequired,
    }).isRequired,
    hasMap: PropTypes.bool,
    selectedPeriod: PropTypes.object,
  };

  static defaultProps = {
    value: undefined,
    valueType: undefined,
    target: null,
    status: null,
    manualStatus: null,
    note: null,
    highlightsTitle: null,
    lowlightsTitle: null,
    highlights: null,
    lowlights: null,
    lastTarget: null,
    indicatorsMilestones: null,
    hasMap: true,
    selectedPeriod: null,
  };

  onImportStatus = (e) => {
    const {
      id,
      title,
      note,
      highlights,
      lowlights,
      highlightsTitle,
      lowlightsTitle,
      actions,
      manualStatus,
      status,
      sectionId,
      projectId,
      indicatorsGroupId,
      hasAccessToImport,
      reportingPeriodType,
    } = this.props;

    // actions.openIndicatorNotesModalState({
    //   indicator: {
    //     id,
    //     title,
    //     note,
    //     highlights,
    //     lowlights,
    //     sectionId,
    //     projectId,
    //     indicatorsGroupId,
    //   },
    // });

    e.preventDefault();
    actions.openImportStatusModalState({
      indicator: {
        id,
        sectionId,
        title,
        manualStatus,
        status,
        note,
        highlightsTitle,
        lowlightsTitle,
        highlights,
        lowlights,
        projectId,
        indicatorsGroupId,
        hasAccessToImport,
        reportingPeriodType,
      },
    });
  };

  onMilestoneClick = (e) => {
    const {
      milestoneLink,
      history,
    } = this.props;

    e.preventDefault();
    history.push(milestoneLink);
  };

  onMapClick = (e) => {
    const {
      mapLink,
      history,
    } = this.props;

    e.preventDefault();
    history.push(mapLink);
  };

  onChartClick = (e) => {
    const {
      chartLink,
      history,
    } = this.props;

    e.preventDefault();
    history.push(chartLink);
  }

  onExpandClick = (e) => {
    const {
      mapLink,
      history,
    } = this.props;

    e.preventDefault();
    history.push(mapLink.replace('/map', ''));
  };

  render() {
    const {
      title,
      value,
      valueType,
      target,
      lastTarget,
      status,
      hasAccessToStatusImport,
      t,
      manualStatus,
      highlights,
      highlightsTitle,
      lowlights,
      lowlightsTitle,
      indicatorsMilestones,
      hasMap,
      selectedPeriod,
      reportingPeriod,
    } = this.props;
    const highlightsLowlights = {
      highlights,
      lowlights,
      highlightsTitle,
      lowlightsTitle,
    };
    const valueUnits = valueType === '%' ? '%' : '';
    const displayedTarget = target !== null ? `${getPraparedValueForShow(target)}${valueUnits}` : t('common.na');
    const indicatorStatus = manualStatus || status;
    const isHighlightPresent = checkHightlightsLowlights(highlightsLowlights);
    const isMilestonesPresent = indicatorsMilestones?.length > 0;
    // eslint-disable-next-line no-mixed-operators
    const showIndicatorStatus = reportingPeriod !== 'all' && indicatorStatus || manualStatus;

    return (
      <S.Card>
        <S.HeadRow>
          <S.Header onClick={this.onMapClick}>
            {formatTitleForCard(title)}
          </S.Header>
          <S.ActionsWrapper>
            {hasAccessToStatusImport
              && <ImportButtonCorner iconType="edit" onImport={this.onImportStatus} titleType={t('common.set')} />}
          </S.ActionsWrapper>
        </S.HeadRow>
        <S.Content onClick={this.onMapClick}>
          <S.RowContainer>
            {showIndicatorStatus && (
              <S.Score>
                <Score value={indicatorStatus} size={24} />
                {/* {indicatorsMilestones.length
                  ? (
                    <S.IconButton status={indicatorStatus} aria-label={t('common.import')} onClick={this.onMilestoneClick}>
                      <S.MilestoneIcon />
                    </S.IconButton>
                  )
                : null} */}
              </S.Score>
            )}
            {value === null || value === undefined ? (
              <S.Result>
                <S.NoResult>{t('common.na')}</S.NoResult>
              </S.Result>
            ) : (
              <S.Result
                status={STATUSES[indicatorStatus]}
                showIndicatorStatus={showIndicatorStatus}
              >
                {getPraparedValueForShow(value)}{valueUnits}
              </S.Result>
            )}
          </S.RowContainer>
          <S.Period>
            {/* {year ? (
              <span>
                {t('common.as_of')} <S.Highlight fontWeight="bold">{monthString} {year}</S.Highlight>
              </span>
            ) : (
              <span>&nbsp;</span>
            )} */}
          </S.Period>
          <S.Target>
            {selectedPeriod && selectedPeriod.period === 'all' ? ''
              : `${lastTarget?.year || ''} ${t('common.target_ind')}: ${displayedTarget}`}
          </S.Target>
        </S.Content>
        {/* <S.ExpandButton aria-label={t('common.expand')} onClick={this.onExpandClick}>
          <S.ExpandIcon fontSize="small" />
        </S.ExpandButton> */}
        <S.IndicatorIcons>
          <S.IndicatorIcon onClick={this.onExpandClick} disabled={!isHighlightPresent}>
            <S.HighlightsImg />
          </S.IndicatorIcon>
          <S.IndicatorIcon onClick={this.onMapClick} disabled={!hasMap || !value}>
            <S.MapImg />
          </S.IndicatorIcon>
          <S.IndicatorIcon onClick={this.onChartClick} disabled={!value}>
            <S.ChartImg />
          </S.IndicatorIcon>
          <S.IndicatorIcon onClick={this.onMilestoneClick} disabled={!isMilestonesPresent}>
            <S.MilestonesImg />
          </S.IndicatorIcon>
        </S.IndicatorIcons>
      </S.Card>
    );
  }
}

const mapStateToProps = ({ auth, indicatorDetails }) => ({
  hasAccessToImport: checkAccessToImport(auth),
  hasAccessToStatusImport: checkAccessToStatusImport(auth),
  reportingPeriod: indicatorDetails.selector.period,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...importActions,
    ...importStatusActions,
    ...updateIndicatorNotesActions,
  }, dispatch),
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Indicator));
