import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import { showedUserRoleMap, filterRoles } from 'helpers/roles';
import FormField from 'components/FormField';
import FormSelect from 'components/FormSelect';
import CountryRow from './CountryRow';
import GlobalVisibilityRow from './GlobalVisibilityRow';

import { validate } from './validation';

import * as S from './styled';

const UserForm = ({
  isFormDisabled,
  onSubmit,
  initialValues,
  isEditing,
  onClose,
  isEditingCurrentUser,
  allowedCountriesIds,
  countries,
  userRole,
  t,
}) => {
  const allCountiesIds = countries.map((c) => c.id);
  const allProjectsIds = countries.flatMap((c) => c.projects.map((p) => p.id));

  const handleSelectAllClick = (formMutators) => () => {
    formMutators.setFieldValue({ name: 'countries', value: allCountiesIds });
    formMutators.setFieldValue({ name: 'projects', value: allProjectsIds });
  };

  const handleUnselectAllClick = (formMutators) => () => {
    formMutators.setFieldValue({ name: 'countries', value: [] });
    formMutators.setFieldValue({ name: 'projects', value: [] });
  };

  const handleCountryChange = (input, values, form) => {
    const isChecked = !input.checked;
    const countryId = input.value;

    if (!isChecked) {
      const country = countries.filter((p) => p.id === countryId)[0];
      const excludedProjectsIds = country.projects.map((p) => p.id);
      form.mutators.setFieldValue({ name: 'projects', value: values.projects.filter((id) => !excludedProjectsIds.includes(id)) });
    }

    if (isChecked) {
      const country = countries.filter((p) => p.id === countryId)[0];
      const selectedProjects = country.projects.map((p) => p.id);
      form.mutators.setFieldValue({ name: 'projects', value: selectedProjects });
    }
  };

  const handleProjectChange = (input, values, form) => {
    const isChecked = !input.checked;
    const projectId = input.value;

    if (isChecked) {
      const projectsCountryId = countries.filter((c) => c.projects.filter((p) => p.id === projectId).length > 0)[0].id;
      form.mutators.setFieldValue({ name: 'countries', value: [...new Set([projectsCountryId, ...values.countries])] });
    }
  };

  const handleVisibilityChange = (input, form) => {
    const isChecked = !input.checked;

    if (isChecked) {
      form.mutators.setFieldValue({ name: 'countries', value: allCountiesIds });
      form.mutators.setFieldValue({ name: 'projects', value: allProjectsIds });
      form.mutators.setFieldValue({ name: 'onGoingAccessCountries', value: allCountiesIds });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => validate(values, allCountiesIds)}
      initialValues={{
        ...initialValues,
        countries: initialValues.countries ? initialValues.countries.map((item) => item.id) : [],
        projects: initialValues.projects ? initialValues.projects.map((item) => item.id) : [],
        onGoingAccessCountries: initialValues.countries ? initialValues.countries
          .filter((c) => c.hasOnGoingAccess)
          .map((c) => c.id) : [],
      }}
      mutators={{
        ...arrayMutators,
        setFieldTouched,
        setFieldValue: ([args], state, utils) => {
          utils.changeValue(state, args.name, () => args.value);
        },
      }}
      render={({
        handleSubmit, hasValidationErrors, errors, values, dirty, form,
      }) => (
        <S.FormContentContainer onSubmit={handleSubmit}>
          <FormField
            field={{
              inputType: 'text',
              label: t('admin.email'),
              value: 'email',
            }}
            disabled={isEditing}
          />
          <S.Row>
            <FormField
              field={{
                inputType: 'text',
                label: t('admin.first_name'),
                value: 'firstName',
              }}
            />
            <FormField
              field={{
                inputType: 'text',
                label: t('admin.last_name'),
                value: 'lastName',
              }}
            />
          </S.Row>
          <S.Row>
            <FormField
              field={{
                inputType: 'text',
                label: t('admin.position'),
                value: 'position',
              }}
            />
            <FormSelect
              field={{
                inputType: 'select',
                label: t('admin.access'),
                value: 'role',
                options: filterRoles(userRole, showedUserRoleMap)
                  .map((key) => ({
                    value: key,
                    name: showedUserRoleMap[key],
                  })),
              }}
              disabled={isEditingCurrentUser}
            />
          </S.Row>

          {/* Countries and Projects */}

          <S.Row>
            <S.Label style={{ color: '#27323e' }}>
              {t('admin.country_and_project_access')}
            </S.Label>

            {/* This is an old requirement, added a false condition so it never appears */}
            {false && allowedCountriesIds.length === allCountiesIds.length && !isEditingCurrentUser && (
              <Button color="primary" onClick={handleSelectAllClick(form.mutators)}>
                {t('admin.select_all')}
              </Button>
            )}

            <S.Div>
              <S.SelectAllButton style={{ paddingRight: '2px' }}>
                <Button color="secondary" onClick={handleSelectAllClick(form.mutators)}>
                  {t('admin.select_all')}
                </Button>
              </S.SelectAllButton>

              <S.SelectAllButton>
                <Button color="secondary" onClick={handleUnselectAllClick(form.mutators)}>
                  {t('admin.unselect_all')}
                </Button>
              </S.SelectAllButton>
            </S.Div>
          </S.Row>

          <div style={{ maxHeight: '400px', overflowY: 'scroll', paddingTop: '1rem' }}>
            <GlobalVisibilityRow
              values={values}
              onVisibilityChange={(input) => handleVisibilityChange(input, form)}
            />

            {countries.map((country, index) => (
              <CountryRow
                key={index}
                country={country}
                values={values}
                onCountryChange={(input) => handleCountryChange(input, values, form)}
                onProjectChange={(input) => handleProjectChange(input, values, form)}
                isDisabled={values.hasGlobalVisibility}
              />
            ))}
          </div>

          {errors.countries
            && (
              <S.Row style={{ padding: '1rem 0', color: 'red' }}>
                {t('admin.one_country_requirement')}
              </S.Row>
            )}

          <DialogActions>
            <Button color="secondary" onClick={onClose}>{t('common.close')}</Button>
            <Button color="primary" disabled={hasValidationErrors || isFormDisabled || !dirty} type="submit">
              {t('common.save')}
            </Button>
          </DialogActions>
        </S.FormContentContainer>
      )}
    />
  );
};

UserForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isEditingCurrentUser: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  allowedCountriesIds: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserForm);
