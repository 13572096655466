import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5e5e5;
  padding: 0 1rem;
  color: ${({ theme }) => theme.countryTheme.colors.mainColor};
`;

export const Div = styled.div``;
