import styled from 'styled-components';

export const FormContentContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  div:first-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Div = styled.div`
  display: flex;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.countryTheme.colors.mainColor};
`;

export const SelectAllButton = styled.div`
  height: 25px;
  color: white;
  border-radius: 4px;
  background: ${({ theme }) => theme.countryTheme.colors.mainColor};

  button {
    color: white;
    text-transform: none;
    padding: 0 8px;
  }
`;
