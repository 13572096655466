import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as actionsIndicatorDetails } from 'redux/indicatorDetails';
import { withTranslation } from 'react-i18next';

import checkHightlightsLowlights from 'helpers/checkHighlightsLowLights';
import * as S from './styled';
import Highlights from '../Highlights';
import Map from '../Map';
import Milestones from '../Milestones';
import Graph from '../Graph';

const Report = ({
  match: {
    params: {
      indicatorId,
      sectionId,
      page,
    },
  },
  milestones,
  lowlightsHighlights,
  data,
  showMap,
  t,
  isFilter,
}) => {
  const showHighlightsData = checkHightlightsLowlights(lowlightsHighlights);
  const showMilestones = isFilter || (milestones && !!milestones.length);
  const showMapData = showMap && !!data;
  const showNoData = !showHighlightsData && !showMapData && !data && !showMilestones;
  const needPrintPageBreak = showMilestones && (showHighlightsData || !!data) && showMapData;

  return (
    <S.ReportContainer>
      {showHighlightsData && <Highlights />}
      {showMapData && <Map indicatorId={indicatorId} page={page} />}
      {!!data && <Graph indicatorId={indicatorId} page={page} />}
      xxx
      {true && (
        <Milestones
          indicatorId={indicatorId}
          sectionId={sectionId}
          page={page}
          needPrintPageBreak={needPrintPageBreak}
        />
      )}
      {showNoData && t('common.no_data')}
    </S.ReportContainer>
  );
};

Report.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      country: PropTypes.string.isRequired,
      indicatorId: PropTypes.string.isRequired,
      sectionId: PropTypes.string.isRequired,
      groupId: PropTypes.string,
      page: PropTypes.string,
    }).isRequired,
  }).isRequired,
  // selector: PropTypes.object.isRequired,
  // countryId: PropTypes.number.isRequired,
  // projectId: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    getIndicatorDetailsListRequest: PropTypes.func.isRequired,
    resetIndicatorDetailsListData: PropTypes.func.isRequired,
  }).isRequired,
  milestones: PropTypes.array.isRequired,
  data: PropTypes.number,
  isFilter: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  lowlightsHighlights: PropTypes.shape({
    highlights: PropTypes.string,
    lowlights: PropTypes.string,
    highlightsTitle: PropTypes.string,
    lowlightsTitle: PropTypes.string,
  }).isRequired,
};

Report.defaultProps = {
  data: null,
};

const mapStateToProps = ({
  indicatorDetails: {
    selector,
    isFilter,
    indicatorData: {
      indicatorsMilestones: milestones,
      value: data,
      hasMap: showMap,
      highlights,
      lowlights,
      highlightsTitle,
      lowlightsTitle,
    },
  },
  country: {
    country,
    selectedProject,
  },
}) => ({
  selector,
  milestones,
  countryId: country ? country.id : null,
  projectId: selectedProject ? selectedProject.id : null,
  data,
  showMap,
  isFilter,
  lowlightsHighlights: {
    highlights,
    lowlights,
    highlightsTitle,
    lowlightsTitle,
  },

});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionsIndicatorDetails,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Report));
