import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export const Root = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div`
  /* margin-top: auto; */
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const GFWButton = styled(Button)`
  && {
    @media print {
      display: none;
    }
  }
`;

export const ActionButton = styled(Link)`
  color: white;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 4px;
  height: 32px;
  padding: 0 0.75rem;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  line-height: 32px;
  text-decoration: none;
`;

export const ActionsContainer = styled.div`
  padding-left: 2rem;
  height: 32px;
  align-self: flex-end;

  a {
    margin-right: 1rem;
  }
`;
