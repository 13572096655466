import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from 'images/ifad-no-text@2x.png';
import logoBlack from 'images/ifad-no-text-black.png';

const logoImage = process.env.REACT_APP_LOGO_URL || logo;
const logoImageInverted = process.env.REACT_APP_LOGO_URL_INVERTED || logoBlack;

export const StyledAppBar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.countryTheme.colors.mainColor};
    color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
    align-items: center;

    @media print {
      display: none;
    }
  }
`;

export const StyledLogo = styled.div`
  && {
    height: 36px;
    width: 64px;
    background-image: ${({ theme }) => (`url(${theme.countryTheme.colors.fontMainColor === '#000000' ? logoImageInverted : logoImage})`)};
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 48px;
    background-position: 50%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    margin-left: 15px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};
    padding: ${({ theme }) => theme.layout.contentPadding};
  }
`;

export const LeftSideContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const RightSideContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const StyledTypography = styled(Typography)`
  && {
    color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
    flex-grow: 1;
  }
`;

export const SLinkContainer = styled.div`
  padding: 0 1rem;
  display: flex;
`;

export const SLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

export const SText = styled.span`
  margin-left: 6px;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
  line-height: 1.5;
`;
