import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import FormCheckbox from 'components/FormCheckbox';
import ExpandIcon from './ExpandIcon';
import * as S from './styled';

const CountryRow = ({
  country,
  values,
  onCountryChange,
  onProjectChange,
  isDisabled,
  t,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleOpen = () => {
    setIsExpanded(!isExpanded);
  };

  const getCountryIsChecked = (value, formValues) => {
    let isChecked = false;

    if (formValues.countries) {
      isChecked = formValues.countries.includes(value);
    }

    return isChecked;
  };

  const getProjectIsChecked = (value, formValues) => {
    let isChecked = false;

    if (formValues.projects) {
      isChecked = formValues.projects.includes(value);
    }

    return isChecked;
  };

  const getOnGoingAccessIsChecked = (value, formValues) => {
    let isChecked = false;

    if (formValues.onGoingAccessCountries) {
      isChecked = formValues.onGoingAccessCountries.includes(value);
    }

    return isChecked;
  };

  return (
    <div>
      <S.Row>
        <FormCheckbox
          field={{
            inputType: 'checkbox',
            name: 'countries',
            label: country.name,
            value: country.id,
          }}
          onChange={onCountryChange}
          // disabled={isEditingCurrentUser || !allowedCountriesIds.includes(country.id)}
          disabled={isDisabled}
          checked={getCountryIsChecked(country.id, values)}
        />
        <Button onClick={toggleOpen}>
          <ExpandIcon isExpanded={isExpanded} />
        </Button>
      </S.Row>

      {isExpanded
        && (
          <S.Div>
            <S.Row style={{
              color: '#757575', paddingLeft: '3rem', paddingTop: '0.2rem', paddingBottom: '0.2rem',
            }}
            >
              <FormCheckbox
                field={{
                  inputType: 'checkbox',
                  name: 'onGoingAccessCountries',
                  label: t('admin.on_going_access'),
                  value: country.id,
                }}
                checked={getOnGoingAccessIsChecked(country.id, values)}
                disabled={isDisabled}
              />
            </S.Row>

            {country.projects.map((project) => (
              <S.Row style={{
                color: '#757575', paddingLeft: '3rem', paddingTop: '0.2rem', paddingBottom: '0.2rem',
              }}
              >
                <FormCheckbox
                  field={{
                    inputType: 'checkbox',
                    name: 'projects',
                    label: project.name,
                    value: project.id,
                  }}
                  disabled={isDisabled}
                  onChange={onProjectChange}
                  checked={getProjectIsChecked(project.id, values)}
                />
              </S.Row>
            ))}
          </S.Div>
        )}
    </div>
  );
};

CountryRow.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  country: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CountryRow);
