import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';

import DialogActions from '@material-ui/core/DialogActions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import * as Material from 'components/material';
import Loader from 'components/Loader';
import { getMilestonesStatusKeys } from 'helpers/milestonesStatuses';
import { MILESTONES_STATUSES } from 'configs/statuses';

import TextField from './TextField';
import Autosuggest from './Autosuggest';
import IndicatorSelect from './IndicatorSelect';
import StatusSelect from './StatusSelect';
import { validate, validateCustomColumnsData } from './validation';

import * as S from './styled';

const getMaxTextLength = (type) => {
  switch (type) {
    case 'participants':
      return 250;
    case 'remarks':
      return 600;
    default:
      return 200;
  }
};

const statusesOptions = (t) => getMilestonesStatusKeys().map((key) => ({
  value: key,
  name: t(`common.${MILESTONES_STATUSES[key].description}`),
}));

const minDate = moment(`${moment().year() - 5}-01-01`);
const maxDate = moment(`${moment().year() + 30}-12-31`);
const namesDateField = ['meetingDate', 'startDate', 'completionDate'];
const namesTextField = ['participants', 'name', 'remarks', 'milestones'];

// the customColumns table does not support localization; this lookup is a quick-fix / workaround for the issue.
const lookups = {
  'Date de réunion': 'common.meeting_date',
  Participants: 'common.participants',
  'Date de début': 'common.start_date',
  Activité: 'common.milestones',
  Responsable: 'common.responsible_party',
  "Date d'achèvement estimée": 'common.estimated_completion_date',
  Observation: 'common.remarks',
};

const MilestoneForm = ({
  isFormDisabled,
  onSubmit,
  initialValues,
  isEditing,
  indicatorsOptions,
  responsibleParties,
  onClose,
  isLoading,
  t,
  i18n,
}) => (

  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={initialValues}
    mutators={{
      ...arrayMutators,
      setFieldTouched,
      setFieldValue: ([args], state, utils) => {
        utils.changeValue(state, args.name, () => args.value);
      },
    }}
    render={({ handleSubmit }) => (
      <S.FormContentContainer onSubmit={handleSubmit}>
        <S.Row>
          <IndicatorSelect
            field={{
              inputType: 'select',
              label: t('admin.choose_indicator'),
              value: 'indicatorId',
              options: indicatorsOptions,
            }}
            disabled={isEditing}
          />
        </S.Row>
        <FieldArray name="customColumnsData" validate={validateCustomColumnsData}>
          {({ fields }) => (
            fields.map((column, index) => {
              if (namesDateField.includes(fields.value[index].milestoneField)) {
                return (
                  <S.Row>
                    <Field name={fields.value[index].milestoneField}>
                      {({ input, meta }) => (
                        <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
                          <S.StyledDatePicker
                            {...input}
                            value={input.value || null}
                            variant="dialog"
                            views={['year', 'month', 'date']}
                            label={
                              t(lookups[fields.value[index].customColumnName]
                                ? lookups[fields.value[index].customColumnName]
                                : fields.value[index].customColumnName)
                            }
                            clearable
                            minDate={minDate}
                            maxDate={maxDate}
                            emptyLabel=""
                            clearLabel={t('common.clear')}
                            cancelLabel={t('common.cancel')}
                            okLabel={t('common.ok')}
                            allowKeyboardControl
                            error={!!(meta.touched && meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    </Field>
                  </S.Row>
                );
              }

              if (fields.value[index].milestoneField === 'responsibleParty') {
                return (
                  <S.Row>
                    <Autosuggest
                      label={fields.value[index].customColumnName}
                      name={fields.value[index].milestoneField}
                      values={responsibleParties}
                    />
                  </S.Row>
                );
              }

              if (fields.value[index].milestoneField === 'status') {
                return (
                  <S.Row>
                    <StatusSelect
                      field={{
                        inputType: 'select',
                        label: fields.value[index].customColumnName,
                        value: fields.value[index].milestoneField,
                        options: statusesOptions(t),
                      }}
                    />
                  </S.Row>
                );
              }

              if (namesTextField.includes(fields.value[index].milestoneField)) {
                return (
                  <S.Row>
                    <S.Column>
                      <S.Label>
                        {t(lookups[fields.value[index].customColumnName]
                          ? lookups[fields.value[index].customColumnName]
                          : fields.value[index].customColumnName)}
                      </S.Label>
                      <TextField
                        field={{
                          inputType: 'text',
                          label: t(lookups[fields.value[index].customColumnName]
                            ? lookups[fields.value[index].customColumnName]
                            : fields.value[index].customColumnName),
                          value: fields.value[index].milestoneField,
                        }}
                        maxTextLength={getMaxTextLength(fields.value[index].milestoneField)}
                      />
                    </S.Column>
                  </S.Row>
                );
              }

              return (
                !fields.value[index].isSystem
                && (
                  <S.Row key={column.customColumnName}>
                    <S.Column>
                      <S.Label>{t(lookups[fields.value[index].customColumnName]
                        ? lookups[fields.value[index].customColumnName]
                        : fields.value[index].customColumnName)}
                      </S.Label>
                      <TextField
                        field={{
                          inputType: 'text',
                          label: t(lookups[fields.value[index].customColumnName]
                            ? lookups[fields.value[index].customColumnName]
                            : fields.value[index].customColumnName),
                          value: `${column}.value`,
                        }}
                        maxTextLength={getMaxTextLength(fields.value[index].milestoneField)}
                      />
                    </S.Column>
                  </S.Row>
                )
              );
            })
          )}
        </FieldArray>
        <DialogActions>
          {!isLoading ? (
            <>
              <Material.Button
                color="secondary"
                onClick={onClose}
                type="button"
              >
                {t('common.close')}
              </Material.Button>
              <Material.Button
                disabled={isFormDisabled}
                color="secondary"
                type="submit"
                variant="contained"
              >
                {t('common.save')}
              </Material.Button>
            </>
          ) : <Loader />}
        </DialogActions>
      </S.FormContentContainer>
    )}
  />
);

MilestoneForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  indicatorsOptions: PropTypes.array.isRequired,
  responsibleParties: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(MilestoneForm);
