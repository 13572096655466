import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormCheckbox from 'components/FormCheckbox';
import * as S from './styled';

const GlobalVisibilityRow = ({
  values,
  onVisibilityChange,
  t,
}) => (
  <div>
    <S.Row style={{ fontWeight: 'bold' }}>
      <FormCheckbox
        field={{
          inputType: 'checkbox',
          name: 'hasGlobalVisibility',
          label: t('admin.global_visibility'),
        }}
        disabled={false}
        checked={values.hasGlobalVisibility}
        onChange={(onVisibilityChange)}
      />
    </S.Row>
  </div>
);

GlobalVisibilityRow.propTypes = {
  values: PropTypes.object.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GlobalVisibilityRow);
